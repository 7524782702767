import apiClient from '../../utils/apiClient';  // Adjust the path to your ApiClient
import { ACCESS_TOKEN } from "../../const";

export const requestVerificationEmail = async () => {
  try {
    // Use the custom API client to make the GET request
    const response = await apiClient.makeRequest({
      method: 'GET',
      url: `/profile/verify-email/${localStorage.getItem(ACCESS_TOKEN)}`, // Your endpoint
    });

    if (response.status === 200) {
      return { success: true, message: 'Verification email sent successfully!' };
    }

    return { success: false, message: 'An error occurred, please try again later.' };
  } catch (error) {
    return { success: false, message: error.message || 'Failed to request verification email.' };
  }
};
