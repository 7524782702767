import { useState, useEffect } from 'react';
import { currentComp } from 'utils/currentComp'; // Import the helper function

const useCurrentCompetition = () => {
  const [currentCompRoute, setCurrentCompRoute] = useState(null);  // Stores the competition route
  const [loading, setLoading] = useState(true);  // Manages loading state
  const [error, setError] = useState(null);  // Tracks errors

  useEffect(() => {
    const fetchCompetition = async () => {
      try {
        const competition = await currentComp();  // Call the helper function
        if (competition) {
          setCurrentCompRoute(`/competition${competition.url}`); // Set the dynamic route
        }
      } catch (err) {
        setError('Failed to fetch competition');
      } finally {
        setLoading(false);  // Stop loading once the fetch is complete
      }
    };

    fetchCompetition();
  }, []);  // Runs only once when the component mounts

  return { currentCompRoute, loading, error };  // Return loading, error, and the route
};

export default useCurrentCompetition;
