import axios from 'axios';
import apiClient from '../../utils/apiClient';  // Adjust the path to your ApiClient
import { toast } from 'react-hot-toast';
import { ACCESS_TOKEN } from  '../../const';

import * as Sentry from '@sentry/nextjs';


export const loginUser = async (token) => {

  if (!token) {
    throw new Error("No token provided");
  }


  // Set the access token in localStorage
  localStorage.setItem(ACCESS_TOKEN, token);
 
  // Fetch the user profile
  const userInfo = await apiClient.makeRequest(
    {
      url: "/accounts/profile/",
      method: "GET",
    },
    {
      context: "Profile Fetch",
      feature: "Authentication",
    }
  );


  // Store the user info in localStorage
  localStorage.setItem("currentUser", JSON.stringify(userInfo));

  // Send the token to the backend to set it
  await apiClient.makeRequest(
    {
      url: "/api/auth/set-token",
      method: "POST",
      data: {
        token,
        currentUser: userInfo.data,
      },
    }
  );

  // Provide feedback and reload
  toast.success("Login successful!");
  
  return { success: true };  // Return success status

}

export const getUserProfile = async (forceFetch = false) => {
  try {
    // Check if the profile fetch time exists in localStorage
    const lastFetchTime = localStorage.getItem('profileFetchTime');
    const currentTime = new Date().getTime();
  
    // If the data is too old (more than 15 minutes) or force fetch is requested
    if (forceFetch || !lastFetchTime || currentTime - lastFetchTime > 15 * 60 * 1000) {
      
      // Fetch the profile from the API
      const userInfo = await apiClient.makeRequest(
        {
          url: '/accounts/profile/',
          method: 'GET',
        },
        {
          context: 'Profile Fetch',
          feature: 'Authentication',
        }
      );



      // Store the user info in localStorage
      localStorage.setItem('currentUser', JSON.stringify(userInfo));
      localStorage.setItem('profileFetchTime', currentTime.toString()); // Update fetch time
      return userInfo; // Return the updated user info
    } else {
      // Use the cached profile if within 15 minutes
      const cachedProfile = localStorage.getItem('currentUser');
      if (cachedProfile) {
        return JSON.parse(cachedProfile);
      }
    }
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        errorType: 'Profile Fetch Error',
        errorContext: 'Authentication Flow',
      },
      extra: {
        error: error.message,
      },
    });
    toast.error('Error fetching profile data');
  }
};


export async function handleLogout() {
  try {
    // Clear auth data first to prevent additional failed requests
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem('currentUser');

    // Attempt API logout
    await axios.post('/api/auth/logout');
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        errorType: 'Logout Error',
        errorContext: 'Authentication Flow'
      },
      extra: {
        error: error.message
      }
    });
  } finally {
    // Always redirect to login
    if (typeof window !== 'undefined') {
      window.location.href = '/login';
    }
  }
} 