import apiClient from 'utils/apiClient'; // Adjust the path to your API client

export const currentComp = async () => {
  try {
    const response = await apiClient.makeRequest(
      {
        url: '/menu/',
        method: 'GET',
      },
      {
        context: 'Getting competition',
        feature: 'Setting Const',
      }
    );

    return response.current_competition[0]; // Assuming we are getting the first current competition
  } catch (error) {
    console.error('Error fetching competition:', error);
    throw error;
  }
};
