import { useEffect, useState } from "react";
import Router from "next/router";
import NProgress from "nprogress"; // nprogress module
import "nprogress/nprogress.css"; // styles of nprogress
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "../index.css";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import NavBar from "../components/NavBar/NavBar";
import Footer from "../components/Footer/Footer";
import LoadingCircleFullPage from "../components/common/LoadingCircleFullPage";
import { basePageWrap } from "../containers/BasePage";
import { Toaster } from "react-hot-toast";
import { getReferralFromUrl, setReferralCookie } from '../utils/referral';
import useAuthRedirect from "../hooks/useAuthRedirect";
import apiClient from "../utils/apiClient";
import { getUserProfile } from "../utils/apiClient/authHelpers";
import posthog from "posthog-js";

config.autoAddCss = false;

NProgress.configure({ showSpinner: false });

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

// Create a client
const queryClient = new QueryClient();

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  useAuthRedirect();  // Add this hook to check cookies on every page load
  const [userInfo, setUserInfo] = useState(null);
  const [isSigningOut, setIsSigningOut] = useState(false);
  const [loading, setLoading] = useState(true);

  async function logout() {
    setIsSigningOut(true);
    try {
      await apiClient.makeRequest({
        url: "/api/auth/logout",
        method: "POST",
        headers: { "Content-Type": "application/json" }
      }, {
        context: 'User Logout',
        feature: 'Authentication'
      });

      if (typeof window !== "undefined") {
        localStorage.removeItem("currentUser");
      }
      setUserInfo(null);
    } finally {
      setIsSigningOut(false);
    }
  }

  // Fetching the profile every 15 minutes with getUserProfile
  // TODO this implemenation not working

  useEffect(() => {
    if (userInfo) {
      const fetchUserProfile = async () => {
        try {
          // Fetch user profile using the existing function
          await getUserProfile(); // forceFetch can be passed here if needed, not passing to check against timeframe in function
        } catch (error) {
          console.error('Failed to fetch user profile:', error);
          toast.error('Failed to load user profile');
        } finally {
          setLoading(false);
        }
      };

      fetchUserProfile();
    }
  }, [userInfo]);


  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedUserInfo = localStorage.getItem("currentUser");
      if (storedUserInfo) {
        try {
          if (storedUserInfo !== "undefined") {
            const parsedUserInfo = JSON.parse(storedUserInfo);
            setUserInfo(parsedUserInfo);
          } else {
            localStorage.removeItem("currentUser");
          }
        } catch (error) {
          console.error("Error parsing user info from localStorage:", error);
          localStorage.removeItem("currentUser");
        }
      }



      const referralCode = getReferralFromUrl();
      if (referralCode) {
        setReferralCookie(referralCode);
      }
    }
  }, []);

  useEffect(() => {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY, {
      api_host: 'https://eu.i.posthog.com',
      person_profiles: 'identified_only',
    });
  }, []);


  if (Component.getLayout) {
    return (
      <QueryClientProvider client={queryClient}>
        {Component.getLayout(
          <Component
            {...pageProps}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            isLoggedIn={!!userInfo}
          ></Component>,
        )}
      </QueryClientProvider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <div className="body">
        <LoadingCircleFullPage show={isSigningOut} />
        <NavBar
          isLoggedIn={!!userInfo}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          logout={logout}
        />
        <Component
          {...pageProps}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          isLoggedIn={!!userInfo}
        />
        <Footer></Footer>
        <Toaster />
      </div>
    </QueryClientProvider>
  );
}

export default basePageWrap(MyApp);
