import axios from 'axios';
import { SentryLogger } from './sentryLogger';
import { ACCESS_TOKEN } from '../../const';
import { toast } from 'react-hot-toast';
import {
  AuthenticationError,
  ForbiddenError,
  NetworkError,
  NotFoundError,
  ServerError
} from './errors';

export class ErrorHandler {
  static async handle(error, config, context) {
    // Don't log auth/logout errors
    if (!error.config?.url?.includes('auth/logout')) {
      await SentryLogger.logError(error, {
        ...context,
        type: this.getErrorType(error),
        message: this.getErrorMessage(error),
        errorContext: this.getErrorContext(error)
      });
    }

    if (error.response) {
      switch (error.response.status) {
        case 401:
        case 403:
          return this.handleAuthError(error, config, context);
        case 404:
          return this.handle404Error(error, context);
        case 408:
        case 429:
        case 500:
          return this.handleRetryableError(error, config, context);
        default:
          return this.handleGeneralError(error, context);
      }
    }

    return this.handleNetworkError(error, config, context);
  }

  static async handleAuthError(error, config, context) {
    if (typeof window === 'undefined' || error.config.url.includes('auth/logout')) {
      throw error;
    }

    // Clear auth data
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem("currentUser");

    // Determine if a custom redirect URL is set in the request config or context
    const customRedirectUrl = config.customRedirectUrl || context.customRedirectUrl;

    // Different messages for 401 vs 403
    const message = error.response?.status === 401
      ? 'Please log in to continue.'
      : 'Your session has expired. Please log in again.';

    toast.error(message);

    // Conditionally override redirect URL
    if (!customRedirectUrl) {
      // Default behavior: Redirect with login parameter
      const url = new URL(window.location.href);
      url.searchParams.set('login', 'true');
      window.location.href = url.toString();
  } else {
      // Use custom redirect URL
      window.location.href = customRedirectUrl;
  }

    throw error.response?.status === 401
      ? new AuthenticationError()
      : new ForbiddenError('Your session has expired');
  }

  static async handleRetryableError(error, config, context) {
    try {
      const retryResponse = await axios({
        ...config,
        timeout: 30000,
      });
      return retryResponse.data;
    } catch (retryError) {
      await SentryLogger.logError(retryError, {
        ...context,
        attempt: 2,
        type: 'RetryFailed',
      });
      throw new ServerError('Server error after retry');
    }
  }

  static async handleGeneralError(error, context) {
    await SentryLogger.logError(error, {
      ...context,
      type: 'GeneralError',
    });

    return {
      error: true,
      status: error.response?.status || 500,
      message: 'An unexpected error occurred',
      details: error.message
    };
  }

  static async handleNetworkError(error, config, context) {
    await SentryLogger.logError(error, {
      ...context,
      type: 'Network Error',
      message: 'Failed to connect to API',
      errorContext: 'API Communication',
    });

    throw new NetworkError();
  }

  static async handle404Error(error, context) {
    await SentryLogger.logError(error, {
      ...context,
      type: 'NotFoundError',
      requestedUrl: error.config?.url
    });

    throw new NotFoundError(error.config?.url);
  }

  static getErrorType(error) {
    switch (error.response?.status) {
      case 401: return 'Authentication Error';
      case 403: return 'Forbidden Error';
      case 404: return 'Not Found Error';
      case 408:
      case 429:
      case 500: return 'Server Error';
      default: return 'General Error';
    }
  }

  static getErrorMessage(error) {
    switch (error.response?.status) {
      case 401: return 'User not authenticated';
      case 403: return 'User lacks permissions';
      case 404: return `Resource not found: ${error.config?.url}`;
      case 408: return 'Request timeout';
      case 429: return 'Too many requests';
      case 500: return 'Internal server error';
      default: return error.message;
    }
  }

  static getErrorContext(error) {
    switch (error.response?.status) {
      case 401:
      case 403: return 'Authentication Flow';
      case 404: return 'Resource Access';
      case 408:
      case 429:
      case 500: return 'Server Communication';
      default: return 'General Flow';
    }
  }
} 