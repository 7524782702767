import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import LoadingCircle from "../common/LoadingCircleFullPage";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { ACCESS_TOKEN } from "../../const";
import apiClient from "../../utils/apiClient";
import { getUserProfile } from "../../utils/apiClient/authHelpers";

const AuthModal = ({ trigger, defaultView = "login", autoOpen, onAutoOpenComplete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [view, setView] = useState(defaultView);
  const [isLoading, setIsLoading] = useState(false);
  const [loginErrors, setLoginErrors] = useState({});
  const [loginGeneralError, setLoginGeneralError] = useState("");
  const [forgotPasswordError, setForgotPasswordError] = useState("");

  useEffect(() => {
    if (autoOpen) {
      setIsOpen(true);
      if (onAutoOpenComplete) {
        onAutoOpenComplete();
      }
    }
  }, [autoOpen, onAutoOpenComplete]);

  const handleOpen = () => {
    setView(defaultView);
    setIsOpen(true);
  };

  async function handleLogin(event, validationResult) {
    setLoginErrors({});
    setLoginGeneralError("");

    if (!validationResult.success) {
      setLoginErrors(validationResult.error.flatten().fieldErrors);
      return;
    }

    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");
    const password = formData.get("password");

    // This appears to be the login logic i dont think it makes sense to have here
    // Have created a reusable login function in authHelpers
    // TODO Change this over to the reusable component

    try {
      setIsLoading(true);
      const response = await apiClient.makeRequest({
        url: '/login/',
        method: 'POST',
        data: {
          email: email.toLowerCase(),
          password,
        }
      }, {
        context: 'User Login',
        feature: 'Authentication'
      });

      // Get token from response
      const { token } = response;

      // Get user profile and set storage
      localStorage.setItem(ACCESS_TOKEN, token);
  
      const userInfo = await getUserProfile(true); // Force fetch on login

      
      // Keep this as is since it's a Next.js API route
      await apiClient.makeRequest({
        url: "/api/auth/set-token",
        method: "POST",
        data: {
          token,
          currentUser: userInfo.data,
        }
      });
    
      toast.success("Login successful!");
      setIsOpen(false);
      window.location.reload();
    } catch (error) {
      console.error("Login error:", error);
      setLoginGeneralError(
        error.response?.data?.non_field_errors?.[0] ||
          error.response?.data?.error ||
          "An error occurred during login",
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function handleForgotPassword(event, validationResult) {
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");
    try {
      setIsLoading(true);
           
      const response = await apiClient.makeRequest({
        url: '/profile/request-password-reset/',
        method: 'POST',
        data: { email }
      }, {
        context: 'Password Reset Request',
        feature: 'Authentication'
      });

      if (response?.success) {
        toast.success("Password reset instructions sent to your email!");
        setView("login");
      } else {
        throw new Error("Unexpected response from the server.");
      }
    } catch (error) {
      console.error("Password reset error:", error);
      setForgotPasswordError(
        error.response?.data?.error || "An error occurred. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  }
  

  return (
    <>
      <div onClick={handleOpen}>{trigger}</div>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
      >
        <DialogBackdrop className="fixed inset-0 bg-black/80" />
        <div className="fixed inset-0 w-screen overflow-y-auto p-4">
          <div className="flex min-h-full items-center justify-center">
            <DialogPanel className="relative w-full max-w-lg rounded-xl bg-black p-16 ring ring-[rgb(207,255,63)]">
              <button
                onClick={() => setIsOpen(false)}
                className="absolute right-4 top-4 text-gray-400 hover:text-gray-300"
                aria-label="Close dialog"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <LoadingCircle show={isLoading} />
              {view === "login" && (
                <LoginForm
                  onSwitchToRegister={() => setView("register")}
                  onSwitchToForgotPassword={() => setView("forgot-password")}
                  onSubmit={handleLogin}
                  errors={loginErrors}
                  generalError={loginGeneralError}
                />
              )}
              {view === "register" && (
                <RegisterForm
                  onSwitchToLogin={() => setView("login")}
                  setIsLoading={setIsLoading}
                />
              )}
              {view === "forgot-password" && (
                <ForgotPasswordForm
                  onSwitchToLogin={() => setView("login")}
                  onSubmit={handleForgotPassword}
                  error={forgotPasswordError}
                />
              )}
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AuthModal;
